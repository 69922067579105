import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import App from './App';
import './styles/main.css';
// import './styles/variables.less';
// import { store } from './store';
import { ConfigProvider } from 'antd';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <ConfigProvider
    theme={{
      token: {
        colorPrimary: '#3ab44d',
        colorSecondary: '#f87d4e',
      },
    }}
  >
    <App />
  </ConfigProvider>
);
