import { Input, Form, Select, Steps, Button, Collapse, Col, Checkbox, Divider, Space } from "antd";
import { useEffect, useState } from "react";
import styles from './index.module.css'
import ReactDOMServer from 'react-dom/server';
import { MinusCircleOutlined, CheckCircleOutlined, PlusOutlined } from '@ant-design/icons';
import diseaseList from './disease.json'

const KYCForm = () => {
    const [current, setCurrent] = useState(0)

    const [step, setStep] = useState(0)
    const [emailForm] = Form.useForm();

    const [otpSent, setOtpSent] = useState(false)
    const [otpVerified, setOtpVerified] = useState(false)

    const [member, setMember] = useState()
    const [err, setErr] = useState('')
    const [loading, setLoading] = useState(false)



    const verifyEmail = async ({ email }) => {
        setLoading(true);
        setErr('');
        await fetch(`https://service.edencaremedical.com/prod/api/v1/member/${String(email).toLowerCase()}/kyc/otp`, {
            method: 'POST',
            headers: {
                ContentType: 'application/json'
            }
        }).then(res => res.json())
            .then(res => {
                if (res?.api?.responseCode === 2230) {
                    setOtpSent(true)
                    setErr('')
                } else {
                    setErr(res?.message)
                }
            })
            .catch(e => setErr('Internal Server Error, Please Contact Admin', console.log(e)))
            .finally(() => { setLoading(false) })
    }

    const verifyOTP = async ({ otp }) => {
        setLoading(true);
        setErr('');
        await fetch(`https://service.edencaremedical.com/prod/api/v1/member/${String(emailForm.getFieldValue('email')).toLowerCase()}/kyc/${otp}/profile`, {
            method: 'GET',
            headers: {
                ContentType: 'application/json'
            }
        }).then(res => res.json())
            .then(res => {
                if (res?.api?.responseCode === 2040) {
                    setOtpVerified(true)
                    setMember(res.result, setStep(1))
                    setErr('')
                } else {
                    setErr(res?.message)
                }
            })
            .catch(e => setErr('Internal Server Error, Please Contact Admin', console.log(e)))
            .finally(() => { setLoading(false) })
    }

    const getDiseaseOptions = (gender) => diseaseList.map(c => { return { label: c.condition, options: c.disease.filter(d => d.gender.includes(gender)).map(d => { return { label: d.name, key: d.name, value: d.name } }) } })
    const [maleConditions, setMaleConditions] = useState(getDiseaseOptions('Male'))
    const [femaleConditions, setFemaleConditions] = useState(getDiseaseOptions('Female'))

    const getMedicalHistoryObj = (diseases) => {
        if (diseases) {
            const selected_conditions = diseases?.map(vc => { return ({ disease_selected: vc, condition: diseaseList.filter(c => c.disease.filter(d => d.name === vc).length > 0)[0].condition }) })
            var groupBy = function (xs, key) {
                return xs.reduce(function (rv, x) {
                    (rv[x[key]] = rv[x[key]] || []).push(x);
                    return rv;
                }, {});
            };
            const keys = Object.keys(groupBy(selected_conditions, 'condition'))
            const values = Object.values(groupBy(selected_conditions, 'condition'))
            const selected = []
            keys.map((key, i) => selected.push({ condition: key, disease_selected: values[i].map(d => d.disease_selected) }))
            return (selected)
        }
        return null
    }

    const [KYCDone, setKYCDone] = useState(false)

    const submitKYC = async (memberType, member_id, i) => {
        setLoading(true)
        const data = [];
        const temp = mainForm.getFieldsValue();

        if (!member?.medical_history_self_declaration_acknowledged) {
            data.push({
                member_id: member.member_id,
                member_type: 'Principal_Member',
                contact_number: temp.contact_number,
                nida_or_passport: temp.nida_or_passport,
                department: temp.department,
                medical_history: getMedicalHistoryObj(temp.conditions)
            })
        }
        member?.dependents?.map((dependent, i) => {
            if (!dependent?.medical_history_self_declaration_acknowledged) {
                data.push({
                    member_id: dependent.member_id,
                    member_type: 'Dependent',
                    contact_number: temp[`dep_${i + 1}_contact_number`],
                    nida_or_passport: temp[`dep_${i + 1}_nida_or_passport`],
                    email: String(temp[`dep_${i + 1}_email`]).toLowerCase(),
                    medical_history: getMedicalHistoryObj(temp[`dep_${i + 1}_conditions`])
                })
            }
        })

        await fetch(`https://service.edencaremedical.com/prod/api/v1/member/kyc`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        }).then(res => res.json())
            .then(res => {
                if (res?.api?.responseCode === 2010) {
                    setKYCDone(true)
                }
                else {
                    setErr(res?.message)
                }
            })
            .catch(e => console.log(e))
            .finally(() => setLoading(false))
    }

    const addItem = (e, gender, memberType) => {
        e.preventDefault();
        if (gender === 'Male') {
            if (maleConditions.filter(a => a.label === 'Other').length === 0) {
                setMaleConditions(maleConditions => [...maleConditions, {
                    label: 'Other',
                    options: [{ label: mainForm.getFieldValue('custom_disease'), value: mainForm.getFieldValue('custom_disease'), key: mainForm.getFieldValue('custom_disease') }]
                }])
            }
            else {
                setMaleConditions(maleConditions => [
                    ...maleConditions.slice(0, 9),
                    maleConditions[9] = { ...maleConditions[9], options: [...maleConditions[9].options, { label: mainForm.getFieldValue('custom_disease'), value: mainForm.getFieldValue('custom_disease'), key: mainForm.getFieldValue('custom_disease') }] }
                ])
            }
        }
        else {
            if (femaleConditions.filter(a => a.label === 'Other').length === 0) {
                setFemaleConditions(femaleConditions => [...femaleConditions, {
                    label: 'Other',
                    options: [{ label: mainForm.getFieldValue('custom_disease'), value: mainForm.getFieldValue('custom_disease'), key: mainForm.getFieldValue('custom_disease') }]
                }])
            }
            else {
                setFemaleConditions(femaleConditions => [
                    ...femaleConditions.slice(0, 9),
                    femaleConditions[9] = { ...femaleConditions[9], options: [...femaleConditions[9].options, { label: mainForm.getFieldValue('custom_disease'), value: mainForm.getFieldValue('custom_disease'), key: mainForm.getFieldValue('custom_disease') }] }
                ])
            }
        }
    };

    const [kycBtns, setKycBtns] = useState([])

    useEffect(() => { mainForm.resetFields() }, [member])

    const [mainForm] = Form.useForm()
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
            <div className={styles.cardShadow} style={{ width: '50%', display: 'flex', flexDirection: 'column', gap: "0px", marginBottom: '75px' }}>
                {KYCDone ? null : <p className={styles.title}>Eden Care Medical History Declaration Form</p>}
                {step !== 0 && !KYCDone ? <p className={styles.subTitle}>Please self-declare your KYC as part of your Eden Care Medical Insurance cover.</p> : null}

                {otpVerified ? null : <p className={styles.subTitle} style={{ marginTop: '25px' }}>
                    As a user of Eden Care Medical, it is important to accurately fill-in and acknowledge your self-medical history as well as that of your dependents.
                    This information is crucial for ensuring that you receive the best possible healthcare coverage and treatment.
                    Please be thorough and honest when providing this information, as any inaccurate or incomplete information could impact your coverage and the quality of care you receive.
                    Eden Care Medical is committed to protecting your privacy and keeping your information confidential.
                    If you have any questions or concerns about the self-medical history declaration process, please don't hesitate to contact Eden Care Medical's customer service team for assistance at <a href='mailto:support@edencaremedical.com'>support@edencaremedical.com</a> or call us at <a href='tel:252 900 100'>252 900 100</a>.
                </p>}

                {otpSent && !otpVerified ?
                    <Form onFinish={verifyOTP} onChange={() => setErr('')}>
                        <p className={styles.formLabel}>Enter OTP received on Registered Email / Registered Contact Number</p>
                        <Form.Item
                            name='otp'
                            rules={[
                                { required: true, message: 'Required' },
                            ]}
                        >
                            <Input className={styles.input} placeholder='Enter OTP' style={{ width: '350px', margin: '10px 0px' }} />
                        </Form.Item>
                        <p style={{ marginTop: '0px', marginLeft: '5px', color: 'red' }}>{err}</p>
                        <Button
                            htmlType='submit'
                            type="primary"
                            size="large"
                            style={{ borderRadius: '15px', width: '125px', marginTop: '10px' }}
                            loading={loading}
                        >
                            Verify OTP
                        </Button>
                    </Form>
                    :
                    <Form hidden={step !== 0} form={emailForm} onFinish={verifyEmail} onChange={() => setErr('')}>
                        <p className={styles.formLabel}>Enter Your Registered Email</p>
                        <Form.Item
                            name='email'
                            rules={[
                                { required: true, message: 'Required' },
                                { type: 'email', message: 'Please enter valid Email' }
                            ]}
                        >
                            <Input className={styles.input} placeholder='Enter your Email' style={{ width: '350px', margin: '10px 0px' }} />
                        </Form.Item>
                        <p style={{ marginTop: '-10px', marginLeft: '5px', color: 'red' }}>{err}</p>
                        <Button
                            htmlType='submit'
                            type="primary"
                            size="large"
                            style={{ borderRadius: '15px', width: '125px', marginTop: '10px' }}
                            loading={loading}
                        >
                            Send OTP
                        </Button>
                    </Form>}
                {KYCDone ?
                    <div>
                        <p className={styles.formTitle}>
                            Thank you for submitting your Medical History KYC form to Eden Care.
                        </p>
                        <p>
                            By providing us with accurate and comprehensive information about your medical history, you have helped us to evaluate your health status and provide you with the best health care options.
                            We assure you that your personal information is kept confidential and secure, and is used only for the purpose of assessing your application for health insurance with Eden Care.
                        </p>
                    </div> :

                    <Form
                        hidden={step === 0}
                        form={mainForm}
                    >
                        <Steps
                            style={{ marginBottom: '35px' }}
                            size="small"
                            current={current}
                            onChange={setCurrent}
                            items={
                                member?.medical_history_self_declaration_acknowledged ? (member?.dependents ? [{ title: 'Principal Member' }, { title: 'Dependents' }] : [{ title: 'Principal Member' }]) :
                                    (member?.dependents ? [{ title: 'Principal Member' }, { title: 'Dependents' }, { title: 'Self Declaration' }] : [{ title: 'Principal Member' }, { title: 'Self Declaration' }])
                            }
                        />



                        <div hidden={current !== 0}>
                            {member?.medical_history_self_declaration_acknowledged === true ?
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '25px' }}>
                                    <CheckCircleOutlined style={{ fontSize: '72px', color: 'rgba(58, 180, 67, 1)' }} />
                                    <p className={styles.formTitle} style={{ fontWeight: '500', fontSize: '18px', }} >You have already acknowledged Self-Declaration Form for Principal Member</p>
                                </div> :
                                <div>
                                    <p className={styles.formTitle} style={{ marginTop: '0px' }} >Member Details</p>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '50%' }}>
                                            <p className={styles.formLabel}>First Name (As per National ID)</p>
                                            <p className={styles.formValue}>{member?.first_name}</p>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '50%' }}>
                                            <p className={styles.formLabel}>Last Name (As per National ID)</p>
                                            <p className={styles.formValue}>{member?.last_name}</p>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '15px' }}>
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', minWidth: '50%' }}>
                                            <p className={styles.formLabel}>Employer</p>
                                            <p className={styles.formValue}>{member?.employer_name}</p>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '50%' }}>
                                            <p className={styles.formLabel}>Department</p>
                                            <Form.Item
                                                style={{ marignBottom: '0px' }}
                                                name='department'
                                                initialValue={member?.department}
                                            >
                                                <Input placeholder="Enter Department" className={styles.input} />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '15px' }}>
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', minWidth: '50%' }}>
                                            <p className={styles.formLabel}>Member Number</p>
                                            <p className={styles.formValue}>{member?.member_number}</p>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '50%' }}>
                                            <p className={styles.formLabel}>NIDA / Passport Number</p>
                                            <Form.Item
                                                initialValue={member?.nida_or_passport}
                                                style={{ marignBottom: '0px' }}
                                                name='nida_or_passport'
                                            >
                                                <Input placeholder="Enter NIDA / Passport Number" className={styles.input} />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', marginTop: '15px' }}>
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', minWidth: '50%' }}>
                                            <p className={styles.formLabel}>Contact Number</p>
                                            <Form.Item
                                                initialValue={member?.contact_number}
                                                style={{ marignBottom: '0px' }}
                                                name='contact_number'
                                                rules={[{
                                                    pattern: new RegExp(/^[0-9+]+$/g),
                                                    message: 'Enter valid Contact Number'
                                                }]}
                                            >
                                                <Input pattern="/^[0-9+]+$/g" placeholder="Enter Contact Number" className={styles.input} />
                                            </Form.Item>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '50%' }}>
                                            <p className={styles.formLabel}>Email</p>
                                            <p className={styles.formValue}>{member?.email}</p>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '15px' }}>
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '50%' }}>
                                            <p className={styles.formLabel}>Date of Birth</p>
                                            <p className={styles.formValue}>{member?.date_of_birth || '--'}</p>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '50%' }}>
                                            <p className={styles.formLabel}>Gender</p>
                                            <p className={styles.formValue}>{member?.gender || '--'}</p>
                                        </div>
                                    </div>
                                    <p className={styles.formLabel} style={{ marginTop: '15px' }}>Select Pre-Existing Conditions</p>
                                    <p className={styles.formLabel} style={{ marginTop: '-2px' }}>If none is applicable , click on Next to Proceed</p>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '15px' }}>
                                        <Form.Item name='conditions' style={{ width: '100%', marginTop: '-12px' }}>
                                            <Select
                                                placeholder='Select Pre-Existing Conditions'
                                                style={{ width: '100%' }}
                                                mode='multiple'
                                                allowClear
                                                options={member?.gender === 'Male' ? maleConditions : femaleConditions}
                                                dropdownRender={(menu) =>
                                                (<>

                                                    {menu}
                                                    <Divider
                                                        style={{
                                                            margin: '8px 0',
                                                        }}
                                                    />
                                                    <Space
                                                        style={{
                                                            padding: '0 8px 4px',
                                                        }}
                                                    >
                                                        <Form.Item name='custom_disease'>
                                                            <Input className={styles.input} placeholder='If any other disease, mention here' />
                                                        </Form.Item>
                                                        <Button type="text" icon={<PlusOutlined />} onClick={(e) => addItem(e, member?.gender)}>
                                                            Add
                                                        </Button>
                                                    </Space>
                                                </>)

                                                }
                                            />
                                        </Form.Item>
                                    </div>
                                </div>
                            }
                        </div>
                        <div hidden={!member?.dependents || (member?.dependents && current !== 1)} id='dependents' style={{ position: 'relative' }}>
                            <p className={styles.formTitle}>Dependents</p>
                            {(member?.dependents || []).map((dependent, i) =>
                                <div style={{ marginLeft: '15px' }} key={i}>
                                    <p className={styles.formTitle} style={{ fontWeight: '500', fontSize: '18px', marginBottom: '10px', marginLeft: '-15px' }}>Dependent {i + 1}</p>
                                    {dependent?.medical_history_self_declaration_acknowledged === true ?
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '25px' }}>
                                            <CheckCircleOutlined style={{ fontSize: '72px', color: 'rgba(58, 180, 67, 1)' }} />
                                            <p className={styles.formTitle} style={{ fontWeight: '500', fontSize: '18px', }} >You have already acknowledged Self-Declaration Form for {dependent?.name}</p>
                                        </div> :
                                        <div>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '50%' }}>
                                                    <p className={styles.formLabel}>Name (As per National ID)</p>
                                                    <p className={styles.formValue}>{dependent?.name || '--'}</p>
                                                </div>
                                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '50%' }}>
                                                    <p className={styles.formLabel}>Relation</p>
                                                    <p className={styles.formValue}>{dependent?.relation || '--'}</p>
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '15px' }}>
                                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', minWidth: '50%' }}>
                                                    <p className={styles.formLabel}>Member Number</p>
                                                    <p className={styles.formValue}>{dependent?.member_number}</p>
                                                </div>
                                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '50%' }}>
                                                    <p className={styles.formLabel}>NIDA / Passport Number</p>
                                                    <Form.Item
                                                        initialValue={dependent?.nida_or_passport}
                                                        style={{ marignBottom: '0px' }}
                                                        name={`dep_${i + 1}_nida_or_passport`}
                                                    >
                                                        <Input placeholder="Enter NIDA / Passport Number" className={styles.input} />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', marginTop: '15px' }}>
                                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', minWidth: '50%' }}>
                                                    <p className={styles.formLabel}>Contact Number</p>
                                                    <Form.Item
                                                        initialValue={dependent?.contact_number}
                                                        style={{ marignBottom: '0px' }}
                                                        name={`dep_${i + 1}_contact_number`}
                                                        rules={[
                                                            {
                                                                pattern: new RegExp(/^[0-9+]+$/g),
                                                                message: 'Please input valid Contact Number',
                                                            },
                                                        ]}
                                                    >
                                                        <Input type="number" placeholder="Enter Contact Number" className={styles.input} />
                                                    </Form.Item>
                                                </div>
                                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '50%' }}>
                                                    <p className={styles.formLabel}>Email</p>
                                                    <Form.Item
                                                        initialValue={dependent?.email}
                                                        style={{ marignBottom: '0px' }}
                                                        name={`dep_${i + 1}_email`}
                                                    >
                                                        <Input placeholder="Enter Email" className={styles.input} />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '15px' }}>
                                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '50%' }}>
                                                    <p className={styles.formLabel}>Date of Birth</p>
                                                    <p className={styles.formValue}>{dependent?.date_of_birth || '--'}</p>
                                                </div>
                                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '50%' }}>
                                                    <p className={styles.formLabel}>Gender</p>
                                                    <p className={styles.formValue}>{dependent?.gender || '--'}</p>
                                                </div>
                                            </div>
                                            <p className={styles.formLabel} style={{ marginTop: '15px' }}>Pre-Existing Conditions</p>
                                            <p className={styles.formLabel} style={{ marginTop: '-2px' }}>If none is applicable , click on Next to Proceed</p>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '15px' }}>
                                                <Form.Item name={`dep_${i + 1}_conditions`} style={{ width: '100%', marginTop: '-12px' }}>
                                                    <Select
                                                        placeholder='Select Pre-Existing Conditions'
                                                        style={{ width: '100%' }}
                                                        mode='multiple'
                                                        options={
                                                            dependent?.gender === 'Male' ? maleConditions : femaleConditions
                                                        }
                                                        allowClear


                                                        dropdownRender={(menu) => (
                                                            <>
                                                                {menu}
                                                                <Divider
                                                                    style={{
                                                                        margin: '8px 0',
                                                                    }}
                                                                />
                                                                <Space
                                                                    style={{
                                                                        padding: '0 8px 4px',
                                                                    }}
                                                                >
                                                                    <Form.Item name='custom_disease'>
                                                                        <Input className={styles.input} />
                                                                    </Form.Item>
                                                                    <Button type="text" icon={<PlusOutlined />} onClick={(e) => addItem(e, member?.gender)}>
                                                                        Add item
                                                                    </Button>
                                                                </Space>
                                                            </>
                                                        )}
                                                    />
                                                </Form.Item>
                                            </div>
                                        </div>}
                                </div>)
                            }
                        </div>

                        <div hidden={(member?.medical_history_self_declaration_acknowledged) || (member?.dependents && current !== 2) || (!member?.dependents && current !== 1)}>
                            <p className={styles.formTitle} style={{ marginTop: '0px' }} >Self Declaration Form</p>

                            <p className={styles.formLabel} style={{ fontWeight: '500', fontSize: '16px' }}>Declaration Acknowledgement</p>
                            <p className={styles.formValue} style={{ fontWeight: '300', fontSize: '14px' }}>This membership declaration is part of the contract with Eden Care Medical, Rwanda.</p>
                            <p className={styles.formLabel} style={{ fontWeight: '500', fontSize: '16px', marginTop: '5px' }}>e-Acknowledgement</p>
                            <ol style={{ marginTop: '0px' }}>
                                <li className={styles.formValue} style={{ fontWeight: '300', fontSize: '14px' }}>
                                    I declare that all the persons named in the application form are members of my immediate family for whose membership I am responsible.
                                </li>
                                <li className={styles.formValue} style={{ fontWeight: '300', fontSize: '14px' }}>
                                    I hereby apply to join the above mentioned health insurance plan.
                                </li>
                                <li className={styles.formValue} style={{ fontWeight: '300', fontSize: '14px' }}>
                                    I understand that any mis-statement or the non-disclosure of any material information in this form will jeopardise my membership.
                                </li>
                                <li className={styles.formValue} style={{ fontWeight: '300', fontSize: '14px' }}>
                                    I warrant that the answers in this form are true, correct, and complete and I acknowledge that such answers are all material.
                                </li>
                                <li className={styles.formValue} style={{ fontWeight: '300', fontSize: '14px' }}>
                                    I hereby authorise the hospital, medical or dental
                                    practitioners who have treated me or any of my dependants to
                                    disclose to the company the records relating to such current or
                                    previous hospitalisations/ medical treatment and to allow the
                                    company to receive extracts from such records and undertake
                                    to assist in obtaining such information.
                                </li>
                                <li className={styles.formValue} style={{ fontWeight: '300', fontSize: '14px' }}>
                                    I have read, understood and agree with the cover options,
                                    exclusions, terms and conditions as stipulated in the product brochure and benefit schedule.
                                </li>
                            </ol>
                            <div style={{ display: 'flex' }}>
                                <Form.Item labelAlign="left"
                                    name="member_ack" valuePropName="checked" >
                                    <Checkbox
                                        onChange={() => setKycBtns(kycBtns => { let temp = [...kycBtns]; temp[0] = !temp[0]; return temp })}
                                    />
                                </Form.Item>
                                <p className={styles.formValue} style={{ marginTop: '5px', marginLeft: '10px', fontWeight: '500', fontSize: '14px' }}>I acknowledge this self-declaration medical history form and confirm that the information presented by me is accurate and correct to my knowledge.</p>
                            </div>

                        </div>

                        <p style={{ marginTop: '0px', marginLeft: '5px', color: 'red' }}>{err}</p>
                        <div style={{ display: 'flex', gap: '15px', marginTop: '25px', marginBottom: '25px' }}>
                            <Button
                                onClick={() => current === 0 ? setStep(0) : setCurrent(current - 1)}
                                disabled={current === 0}
                                size="large"
                                style={{ borderRadius: '15px', width: '100px' }}
                            >
                                Back
                            </Button>
                            {(member?.medical_history_self_declaration_acknowledged && (!member?.dependents || current === 1)) ? null : <Button
                                loading={loading}
                                disabled={(member?.dependents && current == 2) || (!member?.dependents && current == 1) ? !kycBtns[0] : false}
                                onClick={() => (member?.dependents && current == 2) || (!member?.dependents && current == 1) ? submitKYC() : setCurrent(current + 1)}
                                type="primary"
                                size="large"
                                style={{ borderRadius: '15px', width: '100px' }}
                            >
                                {(member?.dependents && current == 2) || (!member?.dependents && current == 1) ? 'Submit' : 'Next'}
                            </Button>}
                        </div>
                    </Form>
                }
            </div>

        </div >)
}

export default KYCForm;